import React from 'react';
import Box from 'components/box';
import Layout from 'components/layout';
import bucket from '../assets/images/bucket.gif';

const NotFound = () => (
  <Layout>
    <Box>
      <img src={bucket} alt="Feeling Lost" />
      <p>
        Some places are great to lose yourself in, but not on this occasion. You
        could try a search or return to our home page.
      </p>
    </Box>
  </Layout>
);

export default NotFound;
